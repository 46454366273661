import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const HomeBannerImage = () => {
    return (
        <StaticImage src="../../images/koestate-home-banner.jpg" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="80" className="img-fluid banner-img" />
        // <video
        //     src="https://ggfx-koestates.s3.eu-west-2.amazonaws.com/i.prod/koestates_video_banner_8448222559.mp4"
        //     alt={`${process.env.GATSBY_SITE_NAME} - Home`}
        //     className="banner-img"
        //     autoPlay="autoplay"
        //     playsInLine="playsinline"
        //     loop="true"
        //     muted="true"
        // />
    )
}

export default HomeBannerImage